import React, { useState, useEffect } from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAsterisk,
  faBolt,
  faCube,
  faSyringe
} from '@fortawesome/free-solid-svg-icons';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { EmployeeIcon } from '../../modules/cs/common/components/employee-icon';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { SpecialitiesArray } from '../../modules/cs/employee/model/specialities-array';
import { IEmployeeNode } from '../../modules/common/model/graphql-types';
import { ConvertToEmployeeModel } from '../../modules/cs/common/utils/DataConverters';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import lodash from 'lodash';

import './tier-list.scss';

interface IEmployeeNodes {
  nodes: IEmployeeNode[];
}

interface IEmployeeEntry {
  allEmployees: IEmployeeNodes;
}

interface IProps {
  data: IEmployeeEntry;
}

const TierListPage: React.FC<IProps> = ({ data }) => {
  const REQUIRED_EMPLOYEES_PER_FACTION = 3;
  const [visibleEmployees, setVisibleEmployees] = useState(
    data.allEmployees.nodes
  );

  const [queryFilter, setQueryFilter] = useState(null);
  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  let factions = [];

  const grps = lodash.groupBy(data.allEmployees.nodes, 'title');
  Object.keys(grps).forEach((key) => {
    if (grps[key].length >= REQUIRED_EMPLOYEES_PER_FACTION) {
      factions.push({
        label: key,
        value: key
      });
    }
  });

  factions = [{ label: 'Any Faction', value: 'all' }].concat(
    lodash.orderBy(factions, 'label')
  );

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'N', value: 'n' },
          { label: 'R', value: 'r' },
          { label: 'SR', value: 'sr' },
          { label: 'SSR', value: 'ssr' },
          { label: 'A-SSR', value: 'a-ssr' }
        ]
      },
      {
        key: 'special',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'alterium',
            tooltip: 'Alternium Reactor',
            image: <FontAwesomeIcon icon={faCube} width="18" />
          },
          {
            value: 'rearm',
            tooltip: 'Rearm',
            image: <FontAwesomeIcon icon={faSyringe} width="18" />
          }
        ]
      },
      {
        key: 'type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Counter',
            tooltip: 'Counter',
            image: (
              <StaticImage
                src="../../images/counterside/icons/type_counter.png"
                width={20}
                alt="Counter"
              />
            )
          },
          {
            value: 'Mech',
            tooltip: 'Mech',
            image: (
              <StaticImage
                src="../../images/counterside/icons/type_mech.png"
                width={20}
                alt="Mech"
              />
            )
          },
          {
            value: 'Soldier',
            tooltip: 'Soldier',
            image: (
              <StaticImage
                src="../../images/counterside/icons/type_soldier.png"
                width={20}
                alt="Soldier"
              />
            )
          },
          {
            value: 'Corrupted Object',
            tooltip: 'Corrupted Object',
            image: (
              <StaticImage
                src="../../images/counterside/icons/type_co.png"
                width={20}
                alt="Corrupted Object"
              />
            )
          }
        ]
      },
      {
        key: 'role',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            tooltip: 'Defender',
            value: 'defender',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_defender.png"
                width={20}
                alt="Defender"
              />
            )
          },
          {
            tooltip: 'Striker',
            value: 'striker',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_striker.png"
                width={20}
                alt="Striker"
              />
            )
          },
          {
            tooltip: 'Ranger',
            value: 'ranger',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_ranger.png"
                width={20}
                alt="Ranger"
              />
            )
          },
          {
            tooltip: 'Sniper',
            value: 'sniper',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_sniper.png"
                width={20}
                alt="Sniper"
              />
            )
          },
          {
            tooltip: 'Supporter',
            value: 'supporter',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_support.png"
                width={20}
                alt="Supporter"
              />
            )
          },
          {
            tooltip: 'Tower',
            value: 'tower',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_tower.png"
                width={20}
                alt="Tower"
              />
            )
          },
          {
            tooltip: 'Siege',
            value: 'siege',
            image: (
              <StaticImage
                src="../../images/counterside/icons/role_siege.png"
                width={20}
                alt="Siege"
              />
            )
          }
        ]
      },
      { key: 'speciality', type: 'dropdown', values: SpecialitiesArray },
      { key: 'faction', type: 'dropdown', values: factions },
      { key: 'reset', type: 'reset' }
    ],
    defaults: queryFilter || {}
  };

  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };

  useEffect(() => {
    let filtered = data.allEmployees.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.fullName
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        if (activeFilters.rarity.toLowerCase() === 'a-ssr') {
          filtered = filtered.filter(
            (emp) =>
              emp.rarity.toLowerCase() === 'ssr' && emp.isAwakened === true
          );
        } else {
          filtered = filtered.filter(
            (emp) =>
              emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase() &&
              emp.isAwakened === false
          );
        }
      }
      if (activeFilters.special && activeFilters.special !== 'all') {
        if (activeFilters.special.toLowerCase() === 'rearm') {
          filtered = filtered.filter((emp) => emp.isRearmed === true);
        } else if (activeFilters.special.toLowerCase() === 'alterium') {
          filtered = filtered.filter((emp) => emp.hasAlternium === true);
        } else {
          filtered = filtered.filter(
            (emp) => emp.hasAlternium === false && emp.isRearmed === false
          );
        }
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.type.indexOf(activeFilters.type) > -1
        );
      }
      if (activeFilters.role && activeFilters.role !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.role.toLowerCase() === activeFilters.role.toLowerCase()
        );
      }
      if (activeFilters.speciality && activeFilters.speciality !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.speciality &&
            emp.speciality.length > 0 &&
            emp.speciality.indexOf(activeFilters.speciality) > -1
        );
      }
      if (activeFilters.faction && activeFilters.faction !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.title.toLowerCase() === activeFilters.faction.toLowerCase()
        );
      }
    }
    if (sortOption) {
      filtered = filtered.sort((a, b) =>
        a[sortOption] < b[sortOption] ? 1 : -1
      );
    }
    setQueryFilter(activeFilters);
    setVisibleEmployees(filtered);
  }, [activeFilters, data.allEmployees.nodes, setQueryFilter, sortOption]);

  const sortingOptions = [
    { value: 'pve_late', label: 'PVE (Late)' },
    { value: 'pve_early', label: 'PVE (Early)' },
    { value: 'pvp_sea', label: 'PVP' }
  ];

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page cs-tier'}
      game="cs"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/counterside/categories/category_tierlist.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Counter Side Tier List</h1>
          <h2>PVE and PVP tier list for Counter Side in the Global region.</h2>
          <p>
            Last updated: <strong>13/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <div className="tier-list-page">
        <SectionHeader
          title={
            <>
              {sortOption === 'pve_early' && 'PVE (Early)'}
              {sortOption === 'pve_late' && 'PVE (Late)'}
              {sortOption === 'pvp_sea' && 'PVP (Global)'}
            </>
          }
        />
        <Accordion defaultActiveKey="0" className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>About the tier list</Accordion.Header>
            <Accordion.Body>
              <p>
                This tier list overhaul has given us the opportunity to address
                many long-standing issues with the tier list and rework
                everything from the ground up.
              </p>
              <p>Key takeaways:</p>
              <ul>
                <li>
                  Character ratings only consider performance in the game modes
                  identified as part of the early or late game.
                </li>
                <li>
                  Character ratings exclusively reflect performance within the
                  criteria they were rated for.
                </li>
                <li>
                  Character ratings are not reflective of those characters’
                  general performance.
                </li>
              </ul>
              <p>
                If you want to learn the details of the PVE overhaul, check out
                this{' '}
                <a
                  href="https://blog.prydwen.gg/2024/02/18/cs-early-late-game-pve-tier-list-overhaul/"
                  target="_blank"
                  rel="noreferrer"
                >
                  blog post
                </a>
                . Some of the information also were added in the PVP tier list
                details tab below.
              </p>
              <p>Special markings:</p>
              <ul>
                <li>
                  <FontAwesomeIcon
                    icon={faBolt}
                    width="18"
                    className="arrow-purple"
                  />{' '}
                  - part of the Terror Deck
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCube}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  - has Alternium Reactor upgrade
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faSyringe}
                    width="18"
                    className="arrow-blue"
                  />{' '}
                  - is a Rearmed character
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>PVE tier list details</Accordion.Header>
            <Accordion.Body>
              <h6>Early PVE</h6>
              <p>
                The previous iteration of the Early PVE Tier List became
                redundant over time because most characters had the same Early
                and Late ratings, and the few truly relevant characters were
                given high arbitrary ratings to signal their importance. A
                painstaking amount of care has been put towards distinguishing
                between Early PVE and Late PVE and setting clear boundaries to
                prevent overlap. We hope our new approach, as detailed below,
                will help return some sense of purpose to the list.
              </p>
              <p>
                The Early PVE Tier List gauges character performance in the
                following PVE content:
              </p>
              <ul>
                <li>Mainstream (Story) Chapters 1~6</li>
                <li>Substreams</li>
                <li>Challenge Mode</li>
                <li>Shadow Palace</li>
                <li>Dives 1~50</li>
              </ul>
              <h6>Late PVP</h6>
              <p>
                The Late PVE Tier List gauges character performance in the
                following PVE content:
              </p>
              <ul>
                <li>Mainstream (Story) Chapters 7+</li>
                <li>Britra, Inhibitor, Kraken Raids</li>
                <li>Consortium Coalition Battle</li>
                <li>Dimension Trimming </li>
                <li>Dives 51+</li>
                <li>Danger Close</li>
              </ul>
              <h6>PVE tier definitions</h6>
              <p>
                It is important to stress that a character’s rating is not
                reflective of their general performance. Under the current
                format, the Prydwen tier list essentially averages character
                performance across some arbitrarily defined “Late game” and
                “Early game” game modes. Certain characters will have high
                ratings because their gimmick is relevant, but this rating does
                not indicate anything regarding their performance outside the
                specific situations you would bring them to.
              </p>
              <ul>
                <li>
                  <strong>SSS</strong> - Characters who almost always represent
                  the strongest option on the team.
                </li>
                <ul>
                  <li>
                    You can expect these characters to have widespread
                    application. These are flexible characters that can be
                    easily slotted into the team and you can expect them to
                    consistently perform far above the baseline relative to
                    other frequently used PVE characters.
                  </li>
                  <li>
                    These characters significantly impact their teams’
                    performance by contributing an incredible amount of value,
                    either as stat stick monsters or through powerful utility
                    built into their kits.
                  </li>
                </ul>
                <li>
                  <strong>SS</strong> - Characters not strong enough to be SSS
                  but better than the average S tier unit.
                </li>
                <ul>
                  <li>
                    These characters are exceptionally good at what they do.
                  </li>
                </ul>
                <li>
                  <strong>S</strong> - Characters that excel in their role/ in a
                  frequently occuring niche.{' '}
                </li>
                <ul>
                  <li>
                    These characters are good at what they do. They are staples
                    members of the roster that will see frequent use across
                    CounterSide’s various PVE game modes.
                  </li>
                </ul>
                <li>
                  <strong>A</strong> - Characters that excel in an uncommon, but
                  important niche.
                </li>
                <ul>
                  <li>
                    These characters are as good as S-tier characters, but there
                    may be less opportunities for them to display their
                    strengths.{' '}
                  </li>
                </ul>
                <li>
                  <strong>B</strong> - Characters playable if you like them
                </li>
                <ul>
                  <li>
                    B-tier characters won’t be the best at what they do but
                    their stats are decent enough that they can contribute to
                    the team. Many of these characters have significant
                    role-overlap with stronger alternatives.
                  </li>
                </ul>
                <li>
                  <strong>C</strong> - Characters with limited application.
                </li>
                <ul>
                  <li>
                    Many characters have one or two very specific use cases for
                    certain stages. These are situations that are more-the-less
                    exclusive to one particular stage. These novel interactions
                    are worth noting, but it does not occur enough for it to be
                    considered a “niche”.
                  </li>
                  <li>
                    Many of these characters do not have the stats to compete
                    against high-tier alternatives that occupy the same niche.
                  </li>
                </ul>
                <li>
                  <strong>D</strong> - Strictly worse than the alternatives.
                </li>
                <ul>
                  <li>
                    These characters struggle to make a noticeable impact, and
                    you are generally discouraged from using them. Oftentimes
                    these characters lack the raw stats needed for them to
                    perform the basic duties of their roles.
                  </li>
                  <li>
                    Contrasting PVP, you won’t be punished for bringing a D-tier
                    character into any particular PVE stage. Given the nature of
                    PVE, it is entirely possible for D-tier characters to
                    perform their role. However, there will be a significant gap
                    in performance between a D-tier character and the stronger
                    alternatives.
                  </li>
                </ul>
                <li>
                  <strong>E</strong> - Unused
                </li>
                <ul>
                  <li>
                    The E tier was deemed unnecessary and so it has been
                    removed. All previously E-rated characters have been shifted
                    up to D.
                  </li>
                  <li>
                    It turns out there’s not much point determining if a bad
                    character is so bad they’re unplayable, or bad but still
                    functional by virtue of their raw stats. Regardless, you
                    wouldn’t use them.
                  </li>
                </ul>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>PVP tier list details</Accordion.Header>
            <Accordion.Body>
              <p>
                Since the last PVP tier list evaluation, stronger and stronger
                characters have been introduced into the game. Balance updates,
                new additions to the Rearmament system, and now the Alternium
                Reactor system continue to push the limits of character
                strength. Over time, the top tiers (SSS/SS/S) have become
                bloated with all the new entries to the point where it has
                become difficult to assess character power level from rating. To
                address this issue, the tier list has been overhauled and the
                character pool has been re-evaluated to properly allocate
                characters as appropriate.
              </p>
              <h6>PVP tier definitions</h6>
              <ul>
                <li>
                  <strong>SSS</strong> - Meta-defining characters who shape the
                  weekly meta with their existence.{' '}
                </li>
                <ul>
                  <li>
                    These characters dictate the weekly meta when they are free.
                    This includes the pacing of games, the viability of lesser
                    comps, and unit considerations when assembling teams.
                  </li>
                  <li>
                    These meta-warping characters see widespread use when they
                    are unbanned.
                  </li>
                </ul>
                <li>
                  <strong>SS</strong> - Solid well-rounded characters with few
                  weaknesses.
                </li>
                <ul>
                  <li>
                    These characters are strong options in the week but they are
                    a tier below meta-defining characters. While they are good,
                    their viability is dependent on the availability of other
                    (SSS) units.
                  </li>
                  <li>
                    The counterplay to these units usually comes from running
                    comps with innately better matchup. Individual counters can
                    be unreliable and/or inefficient.
                  </li>
                </ul>
                <li>
                  <strong> S</strong> - Decent characters with good performance
                  that don’t see heavy use.{' '}
                </li>
                <ul>
                  <li>
                    Many characters are not individually capable of contributing
                    enough to sway the meta, but act as solid supporting units
                    that round out the team by filling the roles that need
                    filling.
                  </li>
                  <li>
                    Many of these characters represent the first-choice option
                    for those roles.
                  </li>
                </ul>
                <li>
                  <strong>A</strong> - Niche characters strong in their niche.{' '}
                </li>
                <ul>
                  <li>
                    These characters have unique gimmicks in their kit that help
                    them stay relevant. Not every comp can take advantage of
                    their gimmicks, but you may periodically find yourself in
                    situations where they “tick all the boxes” for the kind of
                    unit you would need.
                  </li>
                  <li>
                    These units are not bad but they are used considerably less
                    than the higher tier units as their performance swings
                    heavily with the weekly bans.
                  </li>
                </ul>
                <li>
                  <strong>B</strong> - Niche characters usable in their niche.
                </li>
                <ul>
                  <li>
                    These characters have far more situational kits than A-tier
                    units. Many of these characters are outperformed by other
                    similar units that occupy a similar niche. Some of these
                    units have unique utility with limited PVP application.
                  </li>
                  <li>
                    Characters core to niche comps that are otherwise
                    underwhelming individually (mechs and soldiers) have been
                    assigned here unless they are flexible enough to be used
                    outside their namesake comps.
                  </li>
                </ul>
                <li>
                  <strong>C</strong> - Mediocre characters playable on UP
                </li>
                <ul>
                  <li>
                    Many characters are too expensive for what they provide to
                    the team. The buffs applied to UP units are enough to make
                    these mediocre characters relevant.
                  </li>
                </ul>
                <li>
                  <strong>D</strong> - Irredeemably bad.
                </li>
                <ul>
                  <li>Irredeemably bad.</li>
                </ul>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="tier-list-header">
          <p className="title">
            Tier List{' '}
            <span>
              ({sortOption === 'pve_early' && 'PVE - Early'}
              {sortOption === 'pve_late' && 'PVE - Late'}
              {sortOption === 'pvp_sea' && 'PVP'})
            </span>
          </p>
          <p className="sub-title">
            You're currently viewing the{' '}
            <strong>
              {sortOption === 'pve_early' && 'PVE (Early)'}
              {sortOption === 'pve_late' && 'PVE (Late)'}
              {sortOption === 'pvp_sea' && 'PVP'}
            </strong>{' '}
            tier list. It shows{' '}
            {sortOption === 'pve_early' &&
              'how a character performs in Story 1-6, Substreams/Events, Challenge Mode, Shadow Palace and Dive 1-50.'}
            {sortOption === 'pve_late' &&
              'how a character performs in Story chapter 7+, Consortium Coalition Battle, Dimension Trimming, Dives 51+ and Danger Close.'}
            {sortOption === 'pvp_sea' && 'the pvp meta in the Global server.'}
          </p>
        </div>
        <div className="tier-list-switcher">
          <div
            className={`option early ${
              sortOption === 'pve_late' && 'selected'
            }`}
            onClick={() => setSortOption('pve_late')}
          >
            <p>PVE (Late)</p>
          </div>
          <div
            className={`option late ${
              sortOption === 'pve_early' && 'selected'
            }`}
            onClick={() => setSortOption('pve_early')}
          >
            <p>PVE (Early)</p>
          </div>
          <div
            className={`option pvp-sea ${
              sortOption === 'pvp_sea' && 'selected'
            }`}
            onClick={() => setSortOption('pvp_sea')}
          >
            <p>PVP</p>
          </div>
        </div>
        <div className="employees-filter-bar counter-side">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="tier">
          <div className="tier-rating sss">
            <span>SSS</span>
          </div>
          <div className="tier-container">
            {visibleEmployees
              .filter((emp) => emp.newRatings[sortOption] === '11')
              .sort((a, b) => (a.slug > b.slug ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <EmployeeIcon
                      employee={ConvertToEmployeeModel(emp)}
                      enablePopover
                      showSiege
                    />
                  </Card>
                );
              })}
          </div>
        </div>
        <div className="tier">
          <div className="tier-rating ss">SS</div>
          <div className="tier-container">
            {visibleEmployees
              .filter((emp) => emp.newRatings[sortOption] === '10')
              .sort((a, b) => (a.slug > b.slug ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <EmployeeIcon
                      employee={ConvertToEmployeeModel(emp)}
                      enablePopover
                      showSiege
                    />
                  </Card>
                );
              })}
          </div>
        </div>
        <div className="tier">
          <div className="tier-rating s">S</div>
          <div className="tier-container">
            {visibleEmployees
              .filter((emp) => emp.newRatings[sortOption] === '9')
              .sort((a, b) => (a.slug > b.slug ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <EmployeeIcon
                      employee={ConvertToEmployeeModel(emp)}
                      enablePopover
                      showSiege
                    />
                  </Card>
                );
              })}
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297007"></div>
        <div className="tier">
          <div className="tier-rating a">A</div>
          <div className="tier-container">
            {visibleEmployees
              .filter((emp) => emp.newRatings[sortOption] === '8')
              .sort((a, b) => (a.slug > b.slug ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <EmployeeIcon
                      employee={ConvertToEmployeeModel(emp)}
                      enablePopover
                      showSiege
                    />
                  </Card>
                );
              })}
          </div>
        </div>
        <div className="tier">
          <div className="tier-rating b">B</div>
          <div className="tier-container">
            {visibleEmployees
              .filter((emp) => emp.newRatings[sortOption] === '7')
              .sort((a, b) => (a.slug > b.slug ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <EmployeeIcon
                      employee={ConvertToEmployeeModel(emp)}
                      enablePopover
                      showSiege
                    />
                  </Card>
                );
              })}
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
        <div className="tier">
          <div className="tier-rating c">C</div>
          <div className="tier-container">
            {visibleEmployees
              .filter((emp) => emp.newRatings[sortOption] === '6')
              .sort((a, b) => (a.slug > b.slug ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <EmployeeIcon
                      employee={ConvertToEmployeeModel(emp)}
                      enablePopover
                      showSiege
                    />
                  </Card>
                );
              })}
          </div>
        </div>
        <div className="tier">
          <div className="tier-rating d">D</div>
          <div className="tier-container">
            {visibleEmployees
              .filter((emp) => emp.newRatings[sortOption] === '5')
              .sort((a, b) => (a.slug > b.slug ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <EmployeeIcon
                      employee={ConvertToEmployeeModel(emp)}
                      enablePopover
                      showSiege
                    />
                  </Card>
                );
              })}
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297007"></div>
    </DashboardLayout>
  );
};

export default TierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier list | Counter Side | Prydwen Institute"
    description="PVE and PVP tier list for Counter Side Global region."
    game="cs"
  />
);

export const pageQuery = graphql`
  query {
    allEmployees: allContentfulEmployee(
      sort: { fields: fullName, order: ASC }
    ) {
      nodes {
        id
        updatedAt
        createdAt
        unitId
        fullName
        slug
        shortName
        seaReleaseDate
        krReleaseDate
        globalReleaseDate
        regions
        rarity
        rarityNumber
        rarityClassName
        title
        alterniumInfo {
          name
          type
          skill_name
          description
        }
        hasAlternium
        isRearmed
        type
        role
        smallAvatar {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        movementType
        attackType
        speciality
        isAwakened
        showNewTag
        hasBeenBuffedRecently
        newRatings {
          pvp_sea
          pvp_global
          pve_early
          pve_late
        }
        isPartOfTerrorDeck
      }
    }
  }
`;
